body {
  background-color: white;
  margin-left: 10px;
}

.smallSpace {
  margin-left: 5px;
}

.mainLogo {
  height: 125px;
}

.ericsGear {
  padding: 0 0 15px 100px;
  font-size: x-large;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.setupAppHeader {
  color: #0000ff;
  margin: 0 auto;
}

.setupAppSubHeader {
  color: #0c7fe8;
}

.headers {
  color: #840ce8;
}

.expandButton {
  background-color: darkgray;
  padding: 1px;
  font-size: large;
  line-height: 11px;
  height: 16px;
  width: 16px;
}

.indent {
  margin-left: 20px;
}

.indent2 {
  margin-left: 40px;
}

.navigationBar {
  height: 100%;
  float: left;
  border-collapse: collapse;
}

.mainPanel {
  height: 100%;
  width: 75%;
  float: left;
  border-collapse: collapse;
}

.halfWidth {
  width: 50%;
}

.threeQuartersWidth {
  width: 75%;
}

.fullWidth {
  width: 100%;
}

.container {
  display: flex;
}

.logoDiv {
  width: 440px;
}

.headerText {
  padding-top: 25px;
  font-size: larger;
  font-weight: bold;
  flex-grow: 1;
}

.flexGrow {
  flex-grow: 1;
  clear: both;
}

.floatRight {
  float: right;
}

.handCursor {
  cursor: pointer;
  color: #840ce8;
}

.smallFont {
  font-size: x-small;
}

.listBoxHeader {
  height: 40px;
  vertical-align: bottom;
}

.listBox {
  width: 200px;
  height: 100%;
}

.searchResults {
  width: 250px;
}

.smallCell {
  width: 1px;
}

.nowrap {
  white-space: nowrap;
}

.shortCell {
  height: 1px;
}

.hiddenError {
  display: none;
}

.shownError {
  display: block;
  color: red;
  font-weight: bold;
}

.shownNotification {
  display: block;
  color: green;
  font-weight: normal;
}

.hiddenMenu {
  display: none;
}

.shownMenu {
  display: table;
  border: 2px solid gray;
}

.showPayment {
  border: 2px solid gray;
}

.noBorder {
  border: 0;
}

.required {
  font-size: small;
  color: red;
}

.alignTop {
  vertical-align: top;
}

.alignCenter {
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.rightBorder {
  border-left: 1px solid black;
}

.gameNameWidth {
  width: 150px;
}

.tableBorderCollapse {
  border-collapse: collapse;
}

.tableCellLeft {
  margin: 3pt 0 3pt 3pt;
  padding: 3px;
  border-width: 1pt 0 1pt 1pt;
  border-style: solid;
  border-color: black;
}
.tableCellCenter {
  margin: 3pt 0 3pt 0;
  padding: 3px;
  border-width: 1pt 0 1pt 0;
  border-style: solid;
  border-color: black;
}

.tableCellRight {
  margin: 3pt 3pt 3pt 0;
  padding: 3px;
  border-width: 1pt 1pt 1pt 0;
  border-style: solid;
  border-color: black;
}

.solidBorder {
  border: 1px solid black;
}

.loading {
  width: 40px;
}

.imageShortFloatRight {
  width: 150px;
  float: right;
  padding-left: 10px;
}

.imageShortFloatLeft{
  width: 150px;
  float: left;
  padding-left: 10px;
}

.imageTallRight {
  height: 75px;
  float: right;
  padding-left: 10px;
}
